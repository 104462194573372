import { useEffect, useState } from 'react'
import Category from './Category'
import { SITE_URL } from '../../../lib/constants'
import { topProduct } from './FeauredCategory'
import style from './Category.module.css'

const CategoryList = () => {
	const [categoryData, setcategoryData] = useState([])

	const getMenuData = async () => {
		const res = await fetch(`${SITE_URL}api/left-menu`).then((resp) => resp?.json())
		setcategoryData(res?.menu_categories)
	}
	useEffect(() => {
		getMenuData()
	}, [])
	return (
		<>
			<section className={`${style.featuredCategories} pt-20`}>
				<div className='featured-category-wrapper width'>
					<h2 className='text-2xl lg:text-3xl sm:text-3xl font-bold text-center mt-16'>Shop By Category</h2>
					<div className='featured-category-imgs-container grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 w-full'>
						{categoryData?.map((category: topProduct) => (
							<Category key={category?.id} category={category} />
						))}
					</div>
				</div>
			</section>
		</>
	)
}

export default CategoryList
