import Link from 'next/link'
import videosImg from '/public/assets/img/home/videos.jpg'
import downloadsImg from '/public/assets/img/home/downloads.jpg'
import blogsImg from '/public/assets/img/home/blogs.jpg'
import Image from 'next/image'
import { MainContext } from '../../../contexts/MainContext'
import { useContext } from 'react'

const BlogList = (): JSX.Element => {
	const { setIsLoading } = useContext(MainContext)
	return (
		<>
			<section className='blog pt-14 md:pt-28'>
				<div className='width'>
					<div className='blogs-wrapper flex flex-col items-center w-full'>
						<div className='w-full grid sm:grid-cols-2 lg:grid-cols-3 gap-4'>
							<Link href='/blog'>
								<a
									onClick={(e) => {
										setIsLoading(true)
									}}
									href='/blog'
									className={`w-full flex justify-center items-center`}
								>
									<Image src={blogsImg} alt='blogs' />
								</a>
							</Link>
							<Link href='/videos'>
								<a
									onClick={(e) => {
										setIsLoading(true)
									}}
									href='/videos'
									className={`w-full flex justify-center items-center`}
								>
									<Image src={videosImg} alt='videos' />
								</a>
							</Link>

							<Link href='/downloads'>
								<a
									onClick={(e) => {
										setIsLoading(true)
									}}
									href='/downloads'
									className={`w-full flex justify-center items-center sm:-mr-4 lg:mr-0 sm:translate-x-1/2 lg:translate-x-0`}
								>
									<Image src={downloadsImg} alt='downloads' />
								</a>
							</Link>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}

export default BlogList
