import Link from 'next/link'
import Faq from './Faq'
import { faqP } from '../../Faqs/FaqsSection'
interface faqObj {
	Faqs: faqP[]
}

const FaqList = (props: faqObj) => {
	const openAccordian = (e: React.MouseEvent<HTMLUListElement>, id: string) => {
		let accordionOpener = document.querySelectorAll('.accordion-wrapper li h3')
		let textHider = document.querySelectorAll<HTMLElement>('.text-hider')
		let openIcons = document.querySelectorAll<HTMLElement>('.open-icon')

		accordionOpener.forEach((acc, index) => {
			if (e.target === acc || e.target === acc.firstElementChild || acc.contains(e.target as Node)) {
				let thisText = textHider[index]
				let thisOpenIcon = openIcons[index]

				textHider.forEach((hider) => {
					if (hider !== thisText && hider.classList.contains('acc-opened')) {
						openIcons.forEach((icon) => {
							if (icon !== thisOpenIcon && icon.classList.contains('icon-rotated')) {
								icon.classList.remove('icon-rotated')
								icon.style.transform = 'translate(2.5%, -50%) rotate(0deg)'
							}
						})

						hider.classList.remove('acc-opened')
						hider.style.maxHeight = ''
					}
				})

				if (thisText.classList.contains('acc-opened')) {
					thisText.classList.remove('acc-opened')
					if (thisOpenIcon.classList.contains('icon-rotated')) {
						thisOpenIcon.style.transform = 'translate(2.5%, -50%) rotate(0deg)'
					}
					thisText.style.maxHeight = ''
				} else {
					thisText.classList.add('acc-opened')
					thisOpenIcon.classList.add('icon-rotated')
					thisOpenIcon.style.transform = 'translate(2.5%, -50%) rotate(180deg)'
					thisText.style.maxHeight = `${thisText.scrollHeight}px`
				}
			}
		})
	}

	return (
		<>
			<section className='faqs py-14 md:py-28 width'>
				<h2 className='text-2xl lg:text-3xl sm:text-3xl text-center font-bold'>Frequently Asked Question&apos;s</h2>
				<div className='w-full md:w-4/5 lg:w-3/4 mx-auto mt-12'>
					<div className='accordion-container w-full rounded-lg shadow-md bg-gray-100 border border-gray-300 border-solid'>
						{props?.Faqs?.map((faq: faqP) => {
							return <Faq openAccordian={openAccordian} key={faq?.id} faq={faq} />
						})}
					</div>
					<div className='mt-6 flex justify-center'>
						<Link href='/faqs'>
							<a>
								<div>
									<button className='text-white py-4 px-12 rounded-full shadow-lg cursor-pointer w-max font-bold relative primary-blue-bg'>All FAQs</button>
								</div>
							</a>
						</Link>
					</div>
				</div>
			</section>
		</>
	)
}

export default FaqList
