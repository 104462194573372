import React from 'react'
import styles from './SpecialIcons.module.css'

const SpecialIcons = () => {
	return (
		<section className='pt-7 md:pt-14'>
			<div className={`${styles.specialWrapper} width overflow-x-hidden grid grid-cols-2 sm:grid-cols-4 sm:gap-4 md:gap-6`}>
				<div className={`${styles.card} w-full flex flex-col sm:justify-center items-center border sm:border-dashed p-4 rounded bg-white-900 z-30`}>
					<div className='bg-white border border-dashed border-white w-6 h-6 absolute top:1/2 transform translate-y:-1/2 -left-6 z-0' />
					<div className='bg-white border border-dashed border-white w-6 h-6 absolute top:1/2 transform translate-y:-1/2 -right-6 z-40 sm:z-0' />
					<div className='primary-blue-bg  p-2 sm:p-4 lg:p-4 rounded-full'>
						<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth={1} stroke='var(--white)' className={`${styles.specialIcons} w-10 md:w-16 sm:w-12 h-10 md:h-16 sm:h-12`}>
							<path
								strokeLinecap='round'
								strokeLinejoin='round'
								d='M8.25 18.75a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 0 1-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 0 0-3.213-9.193 2.056 2.056 0 0 0-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 0 0-10.026 0 1.106 1.106 0 0 0-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12'
							/>
						</svg>
					</div>

					<h2 className={`${styles.specialText} text-center text-sm sm:text-lg font-semibold mt-2`}>Free Delivery</h2>
				</div>
				<div className={`${styles.card} w-full flex flex-col sm:justify-center items-center border sm:border-dashed p-4 rounded bg-white-900 z-30`}>
					<div className='bg-white border border-dashed border-white w-6 h-6 absolute top:1/2 transform translate-y:-1/2 -left-6 z-0' />
					<div className='bg-white border border-dashed border-white w-6 h-6 absolute top:1/2 transform translate-y:-1/2 -right-6 z-40 lg:z-0' />
					<div className='primary-blue-bg  p-2 sm:p-4 lg:p-4 rounded-full'>
						<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth={1} stroke='var(--white)' className={`${styles.specialIcons} w-10 md:w-16 sm:w-12 h-10 md:h-16 sm:h-12`}>
							<path
								strokeLinecap='round'
								strokeLinejoin='round'
								d='M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 0 1-.825-.242m9.345-8.334a2.126 2.126 0 0 0-.476-.095 48.64 48.64 0 0 0-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0 0 11.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155'
							/>
						</svg>
					</div>

					<h2 className={`${styles.specialText} text-center text-sm sm:text-lg font-semibold mt-2`}>24/7 Support</h2>
				</div>

				<div className={`${styles.card} w-full flex flex-col sm:justify-center items-center border sm:border-dashed p-4 rounded bg-white-900 z-30`}>
					<div className='bg-white border border-dashed border-white w-6 h-6 absolute top:1/2 transform translate-y:-1/2 -left-6 z-0' />
					<div className='bg-white border border-dashed border-white w-6 h-6 absolute top:1/2 transform translate-y:-1/2 -right-6  z-40 md:z-0' />
					<div className='primary-blue-bg p-2 sm:p-4 lg:p-4 rounded-full'>
						<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth={1} stroke='var(--white)' className={`${styles.specialIcons} w-10 md:w-16 sm:w-12 h-10 md:h-16 sm:h-12`}>
							<path strokeLinecap='round' strokeLinejoin='round' d='M6 13.5V3.75m0 9.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 9.75V10.5' />
						</svg>
					</div>

					<h2 className={`${styles.specialText} text-center text-sm sm:text-lg font-semibold mt-2`}>Free Repairing</h2>
				</div>

				<div className={`${styles.card} flex flex-col sm:justify-center items-center border sm:border-dashed p-4 rounded bg-white-900`}>
					<div className='bg-white border border-dashed border-white w-6 h-6 absolute top:1/2 transform translate-y:-1/2 -left-6 z-0' />
					<div className='bg-white border border-dashed border-white w-6 h-6 absolute top:1/2 transform translate-y:-1/2 -right-6 z-40' />

					<div className='primary-blue-bg  p-2 sm:p-4 lg:p-4 rounded-full'>
						<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth={1} stroke='var(--white)' className={`${styles.specialIcons} w-10 md:w-16 sm:w-12 h-10 md:h-16 sm:h-12`}>
							<path
								strokeLinecap='round'
								strokeLinejoin='round'
								d='M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z'
							/>
						</svg>
					</div>

					<h2 className={`${styles.specialText} text-center text-sm sm:text-lg font-semibold mt-2`}>5 Years Warranty</h2>
				</div>
			</div>
		</section>
	)
}

export default SpecialIcons
