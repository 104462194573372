import Image from 'next/image'
import { imgApiUrl } from '../../lib/constants'
import styles from './HeroSection.module.css'
import Link from 'next/link'
import { SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination } from 'swiper/modules'
import SwiperSlider from '../Swiper/SwiperSlider'
import { MainContext } from '../../contexts/MainContext'
import { useContext, useEffect, useState } from 'react'
interface sliderData {
	banner_text: string
	id: number
	image: string
	link: string
	name: string
	status: string
	updated_at: string
}
interface sliderProps {
	banners: sliderData[]
	mobileBanners: sliderData[]
}
const HeroSection = ({ mobileBanners, banners }: sliderProps): JSX.Element => {
	const { setIsLoading } = useContext(MainContext)
	const [isSmallScreen, setIsSmallScreen] = useState(false)

	useEffect(() => {
		const handleResize = () => {
			setIsSmallScreen(window.innerWidth < 576)
		}

		handleResize()
		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])
	const isbanner = isSmallScreen ? mobileBanners : banners
	const mwidth = isSmallScreen ? 576 : 1920
	const mheight = isSmallScreen ? 400 : 600
	return (
		<>
			<section className={`home-hero w-full relative m-auto`}>
				<SwiperSlider
					navigation={false}
					slidesPerView={1}
					slidesPerGroup={1}
					loop={true}
					speed={1500}
					autoplay={{
						delay: 5000,
						disableOnInteraction: true
					}}
					spaceBetween={30}
					pagination={{
						clickable: true
					}}
					modules={[Autoplay, Pagination]}
					className={'mySwiper'}
					breakpoints={{
						1440: {
							slidesPerView: 1,
							slidesPerGroup: 1
						}
					}}
				>
					{isbanner?.map((banner: sliderData, index: number) => (
						<SwiperSlide key={index}>
							<Link href={`${banner?.link}`}>
								<div className={`cursor-pointer ${styles.hero_slider}`} onClick={() => setIsLoading(true)}>
									<Image priority={index === 0} src={`${imgApiUrl?.homeSliderBanner}/${banner?.image}`} layout='responsive' width={mwidth} height={mheight} alt={banner?.name} />
								</div>
							</Link>
						</SwiperSlide>
					))}
				</SwiperSlider>
			</section>
		</>
	)
}

export default HeroSection
