import React from 'react'
import styles from './SpecialOffers.module.css'
import Product from '../Products/Product'
import { featuredPro } from '../Products/ProductList'
interface specialOffer {
	specialOffers: featuredPro[]
}
const SpecialOffers = ({ specialOffers }: specialOffer) => {
	return (
		specialOffers?.length > 0 && (
			<section className={`${styles.special_offers} py-10 md:py-28 mt-14 md:mt-28`}>
				<div className='featured-category-wrapper width'>
					<h2 className='text-2xl lg:text-3xl sm:text-3xl font-bold text-center'>Special Offers</h2>
					<div className='lg:grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 rounded-lg overflow-hidden shadow-lg mt-6 h-full w-full hidden'>
						<div className={`${styles.offers_wrapper} grid grid-cols-1 border-b border-r border-solid border-gray-200 h-full w-full`}>
							{specialOffers?.slice(0, 2)?.map((offer: featuredPro, index: number) => {
								return <Product key={index} className={`${index === 0 ? 'border-b' : undefined} ${index === 0 ? 'border-solid' : undefined} ${index === 0 ? 'border-gray-200' : undefined} bg-white ${styles.col}`} type='offer' product={offer} />
							})}
						</div>

						<div className='lg:col-start-2 lg:col-end-4 grid grid-cols-1 h-full w-full'>
							<div className='border-b border-solid border-gray-200 border-t sm:border-t-none'>
								<Product type='offer-horizontal' product={specialOffers[3]} />
							</div>
							<Product type='offer-horizontal' product={specialOffers[4]} />
						</div>

						<div className={`grid sm:col-start-1 sm:col-end-3 md:col-span-1 sm:grid-cols-2 md:grid-cols-1 border-l border-r border-solid border-gray-200 sm:border-t md:border-t-none h-full w-full ${styles.col}`}>
							<div className='border-b border-solid border-gray-200 bg-white sm:border-r md:border-r-none border-t sm:border-t-none'>
								<Product type='offer' product={specialOffers[5]} />
							</div>

							<Product type='offer' product={specialOffers[6]} />
						</div>
					</div>

					{/* for small devices */}
					<div className='grid sm:grid-cols-2 md:grid-cols-3 pb-4 lg:grid-cols-4 rounded-lg overflow-hidden mt-6 h-full w-full lg:hidden gap-4'>
						{specialOffers?.slice(0, 6)?.map((offer: any) => {
							return <Product className={` bg-white shadow-lg`} type='offer' product={offer} />
						})}
					</div>
				</div>
			</section>
		)
	)
}

export default SpecialOffers
