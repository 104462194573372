import { useContext, useEffect } from 'react'
import { MainContext } from '../../../contexts/MainContext'
import Product from './Product'
import styles from './Product.module.css'
import { SwiperSlide } from 'swiper/react'
import SwiperSlider from '../../Swiper/SwiperSlider'
import { Navigation } from 'swiper/modules'
import style from './Product.module.css'

export interface featuredPro {
	additional_information: string | null
	canonical_url: string | null
	check_quantity: string
	featured: string
	full_description: string
	id: number
	image: string
	image_org: string
	image_updated: string
	in_stock: string
	is_canonical: string
	meta_description: string
	meta_keywords: string
	meta_title: string
	name: string
	parent_id: number
	price: number
	price_catalog: number
	price_discounted: number
	price_discounted_end: string | null
	price_discounted_start: string | null
	quantity: number | null
	short_description: string
	sku: string
	slug: string
	status: string
	type: string
}
interface productData {
	data: featuredPro[]
}
interface product {
	Products: productData
}
const ProductList = (props: product): JSX.Element => {
	const { setIsLoading } = useContext(MainContext)

	useEffect(() => {
		setIsLoading(false)
	}, [setIsLoading])

	return (
		<>
			<section className={` ${style.featuredProducts} featured-products-home pt-14 md:pt-20 bg-gray-100 fc-home`}>
				<div className='featured-products-wrapper width flex flex-col items-center'>
					<h2 className='text-2xl lg:text-3xl sm:text-3xl font-bold text-center'>Our Featured Products</h2>
					<div className='featured-categories-imgs-container mt-10 w-full'>
						<SwiperSlider
							navigation={true}
							modules={[Navigation]}
							spaceBetween={2}
							slidesPerView={1}
							slidesPerGroup={1}
							loop={true}
							speed={1500}
							autoplay={false}
							pagination={false}
							className={'custProductSwiper'}
							breakpoints={{
								1440: {
									slidesPerView: 5,
									slidesPerGroup: 5
								},
								1024: {
									slidesPerView: 3,
									slidesPerGroup: 3
								},
								786: {
									slidesPerView: 2,
									slidesPerGroup: 2
								},
								575: {
									slidesPerView: 2,
									slidesPerGroup: 2
								}
							}}
						>
							{props?.Products?.data?.map(
								(product: featuredPro, index: number) =>
									product && (
										<SwiperSlide key={index} className={styles.product_card}>
											<Product key={product?.id} product={product} />
										</SwiperSlide>
									)
							)}
						</SwiperSlider>
					</div>
				</div>
			</section>
		</>
	)
}

export default ProductList
