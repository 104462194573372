import React, { useState, useEffect } from 'react'
import { imgApiUrl } from '../../lib/constants'
import 'slick-carousel/slick/slick.css'
import Image from 'next/image'
interface sliderData {
	banner_text: string
	id: number
	image: string
	link: string
	name: string
	status: string
	updated_at: string
}
interface sliderProps {
	banners: sliderData[]
}

const DentalBanner = (props: sliderProps) => {
	const [isSmallScreen, setIsSmallScreen] = useState(false)

	useEffect(() => {
		const handleResize = () => {
			setIsSmallScreen(window.innerWidth < 576)
		}

		handleResize() // Initial check
		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	const bannerToShow = isSmallScreen ? props?.banners[0] : props?.banners[1]
	const bannerWidth = bannerToShow === props?.banners[0] ? 2200 : 1920
	const bannerHeight = bannerToShow === props?.banners[0] ? 1500 : 500

	return (
		<>
			<section className={` w-full relative m-auto`}>
				<a href={`${bannerToShow?.link}`}>
					<div className={`cursor-pointer `}>
						<Image src={`${imgApiUrl?.homeSliderBanner}/${bannerToShow?.image}`} layout='responsive' width={bannerWidth} height={bannerHeight} alt={bannerToShow?.name} />
					</div>
				</a>
			</section>
		</>
	)
}

export default DentalBanner
