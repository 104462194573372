import React from 'react'
import { Swiper } from 'swiper/react'
import 'swiper/css'

const SwiperSlider = ({ navigation, spaceBetween, slidesPerView, slidesPerGroup, loop, speed, children, autoplay, pagination, modules, className, breakpoints }) => {
	return (
		<Swiper navigation={navigation} modules={modules} spaceBetween={spaceBetween} slidesPerView={slidesPerView} slidesPerGroup={slidesPerGroup} loop={loop} speed={speed} autoplay={autoplay} pagination={pagination} className={className} breakpoints={breakpoints}>
			{children}
		</Swiper>
	)
}

export default SwiperSlider
