import React from 'react'
import Category from './Category'
import { SwiperSlide } from 'swiper/react'
import SwiperSlider from '../../Swiper/SwiperSlider'
import { Navigation } from 'swiper/modules'
import style from './Category.module.css'

interface topSelling {
	topSellingCategories: topProduct[]
}
export interface topProduct {
	canonical_url: string | null
	display_mode: string
	do_index: string
	id: number
	image: string
	image_main: string
	is_all: string
	is_canonical: string
	is_featured: string
	is_main: string
	link_to: string | null
	link_type: string
	meta_description: string
	meta_keywords: string
	meta_title: string
	name: string
	parent_id: number
	position: number | null
	show_in_menu: string
	slug: string
	status: string
	onClick: () => void
	className: string
}
const FeauredCategory = (props: topSelling): JSX.Element => {
	return (
		<section className={`${style.featuredCategories} pt-14 pb-28`}>
			<div className='featured-category-wrapper width'>
				<h2 className='text-2xl lg:text-3xl sm:text-3xl font-bold text-center mt-8 lg:mt-0'>Featured Categories</h2>
				<div className='mt-6 w-full'>
					<SwiperSlider
						navigation={true}
						modules={[Navigation]}
						spaceBetween={30}
						slidesPerView={1}
						slidesPerGroup={1}
						loop={true}
						speed={1500}
						autoplay={false}
						pagination={false}
						className={'custSwiper'}
						breakpoints={{
							1440: {
								slidesPerView: 5,
								slidesPerGroup: 5
							},
							1024: {
								slidesPerView: 4,
								slidesPerGroup: 4
							},
							786: {
								slidesPerView: 3,
								slidesPerGroup: 3
							},
							575: {
								slidesPerView: 2,
								slidesPerGroup: 2
							}
						}}
					>
						{props?.topSellingCategories?.map((category, index) => (
							<SwiperSlide key={index} className='px-4'>
								<Category category={category} />
							</SwiperSlide>
						))}
					</SwiperSlider>
				</div>
			</div>
		</section>
	)
}

export default FeauredCategory
